import React, { useState } from "react";
import {
  Wrapper,
  WrapperItem,
  FormWrapper,
  FormFieldWrapper,
  InputWrapper,
  ButtonWrapper,
  TestTile,
  ImagesWrapper,
  CityInfos,
  ImageWrapper,
  Presse,
  WrapperItem2,
  AdaptHour
} from "./ContactezNous.module.css";
import { GrayTitle } from "./../../../css/Title.module.css";
import Button from "./../../../components/Button";
import FacadeGhostParis from "../../../res/Façade Ghost Paris.png"

const ContactezNous = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [message, setMessage] = useState("");

  const handleSendButton = () => {
    if (
      typeof window !== "undefined" &&
      firstname.length > 0 &&
      lastname.length > 0 &&
      email.length > 0 &&
      tel.length > 0 &&
      message.length > 0
    ) {
      window.location.href =
        "mailto:contact@ghostgalerie.com?subject=" +
        firstname +
        "%20" +
        lastname +
        "&body=Bonjour%2C%0A%0A" +
        message +
        "%0A%0AVous%20pouvez%20me%20contacter%20par%20mail%20a%20l%27adresse%20suivante%20" +
        email +
        "%20ou%20par%20telephone%20au%20" +
        tel.replace(" ", "%20") +
        ".";
    }
  };

  return (
    <div className={Wrapper}>
      <div className={WrapperItem}>
        <h1 className={GrayTitle} style={{fontSize:"20px"}}>CONTACT US</h1>
        <form className={FormWrapper} style={{paddingTop:"13px"}}>
          <div className={FormFieldWrapper}>
            <label htmlFor="text">Last name<b style={{color:"red", fontSize:"20px"}}>*</b></label>
            <input
              className={InputWrapper}
              type="text"
              placeholder="Enter your name..."
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
          <div className={FormFieldWrapper}>
            <label htmlFor="text">First name<b style={{color:"red", fontSize:"20px"}}>*</b></label>
            <input
              className={InputWrapper}
              type="text"
              placeholder="Enter your first name..."
              onChange={(e) => setFirstname(e.target.value)}
            />
          </div>
          <div className={FormFieldWrapper}>
            <label htmlFor="text">Email <b style={{color:"red", fontSize:"20px"}}>*</b></label>
            <input
              className={InputWrapper}
              type="text"
              placeholder="Enter your email..."
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={FormFieldWrapper}>
            <label htmlFor="text">Phone</label>
            <input
              className={InputWrapper}
              type="text"
              placeholder="Enter your phone number..."
              onChange={(e) => setTel(e.target.value)}
            />
          </div>
          <div className={FormFieldWrapper}>
            <label htmlFor="text" style={{paddingBottom: "10px"}}>Message<b style={{color:"red", fontSize:"20px"}}>*</b></label>
            <textarea
              rows="4"
              cols="10"
              placeholder="Écrivez your message..."
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                handleSendButton();
              }}
            >
              Send
            </Button>
          </div>
        </form>
        </div>
        <div>
          <h1 className={GrayTitle} style={{fontSize:"20px"}}>ADDRESSES</h1>
          <div className={TestTile}>
          <div className={CityInfos}>
            <div classname={WrapperItem}>
              <h2><strong>Marseille</strong></h2>
              <a target="_blank" href="https://www.google.com/maps/place/2+Rue+de+Belloi,+13006+Marseille/@43.2892859,5.3751832,17z/data=!3m1!4b1!4m5!3m4!1s0x12c9c0b74684c4e3:0xf5c978b6743c89ab!8m2!3d43.2892859!4d5.3773719">
                <h5>2 rue de Belloi<br/>Marseille 13006</h5>
              </a>
              <h5 className={AdaptHour}><strong>Opening Hours</strong></h5>
              <h5 >Monday to Friday 9:30am to 18:30am</h5>
              <h5>+33 (0)4 88 60 05 76</h5>
            </div>
            <div classname={WrapperItem}>
              <h2 className={WrapperItem}><strong>Paris</strong></h2>
            <a target="_blank" href="https://www.google.com/maps/place/11+Rue+de+Miromesnil,+75008+Paris/@48.8719848,2.3131828,17z/data=!3m1!4b1!4m6!3m5!1s0x47e66fc93339e06b:0x121e8cb2ae0fdb11!8m2!3d48.8719848!4d2.3157577!16s%2Fg%2F11b8v480w0?entry=ttu">

                <h5>11 rue de Miromesnil<br/>Paris 75008</h5>
              </a>
              <h5><strong>Opening Hours</strong></h5>
              <h5 style={{marginBottom:"0px"}}>Monday to Saturday 10:30am to 6:30pm</h5>
              <h5>Sunday by appointment</h5>
              <h5>+33 (0)1 85 73 27 99</h5>
            </div>
            </div>
            <div className={ImagesWrapper}>
              {/* <img className={ImageWrapper} src={FacadeGhostParis} alt="Façade Ghost Paris"/> */}
            <div className={WrapperItem2}>
              {/* <h1 style={{fontSize:"20px",  paddingTop: "50px"}}>PRESSE</h1> */}
          <br />
          {/* <div className={Presse}>
            <div>
              <p style={{fontSize: "16px"}}>Heloise Guillet</p>
              <p style={{fontSize: "16px"}}>heloise@be-rp.fr</p>
              <p style={{fontSize: "16px"}}>Tél.: 07 62 58 36 06</p>
              <p style={{fontSize: "16px"}}>www.be-rp.fr</p>
              <br />
              </div>
          </div> */}
          </div>

            </div>
          </div>
          
        </div>
        <div></div>
    </div>
  );
};

export default ContactezNous;
